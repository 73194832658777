<template>
  <div>
    <el-form :model="addform" :rules="rules" ref="addform" label-width="180px">
      <el-form-item :label="$t('xt.juese')" prop="role">
        <el-select v-model="addform.role" :placeholder="$t('qxz')">
          <el-option
            v-for="item in roles"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('yhm')" prop="username">
        <el-input v-model="addform.username"></el-input>
      </el-form-item>
      <el-form-item :label="$t('xt.mima')" prop="password">
        <el-input v-model="addform.password" type="password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('xt.eqpass')" prop="password2" required>
        <el-input v-model="addform.password2" type="password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('xt.sfbs')" prop="isreal">
        <el-radio-group v-model="addform.isreal">
          <el-radio label="1">{{ $t('xt.zsyh') }}</el-radio>
          <el-radio label="0">{{ $t('xt.xnyh') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="adduser('addform')">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="resetform()">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    // 自定义验证规则
    const validatePass = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.addform.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      addform: {
        username: '',
        password: '',
        password2: '',
        isreal: '',
        role: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        password2: [{ validator: validatePass, trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'change' }],
        isreal: [{ required: true, message: '请选择身份标识', trigger: 'blur' }]
      },
      roles: []
    }
  },
  created() {
    this.getroles()
  },
  methods: {
    async getroles() {
      const { data } = await this.$http.get('/admin/system/adduser')
      if (data) {
        if (data.code === 200) {
          this.roles = data.data
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    },
    async adduser() {
      const { data } = await this.$http.post(
        '/admin/system/adduser',
        this.addform
      )
      if (data.code === 200) {
        this.$message.success(this.getlang(data.msg))
        this.$refs.addform.resetFields()
      } else {
        this.$message.error(this.getlang(data.msg))
      }
    },
    resetform() {
      this.$refs.addform.resetFields()
    }
  }
}
</script>
